<template>
  <div class="honor">
    <h1 class="title">企业荣誉</h1>
    <swiper class="mySwiper" :options="swiperOption">
      <swiper-slide class="mySlide" v-for="(slide,index) in honors" :key="index">
        <img class="img" :src="slide.img" alt="">
        <p class="name">{{slide.name}}</p>
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "Honor",
  data() {
    return {
      honors: [
        {
          img: require('../../assets/img/about/honor/tech_giant.png'),
          name: '上海科技小巨人企业'
        },
        {
          img: require('../../assets/img/about/honor/tech_zjtx.png'),
          name: '上海"专精特新"企业'
        },
        // {
        //   img: require('../../assets/img/about/honor/2016年度新三板最具创新力奖.png'),
        //   name: '2016年度新三板最具创新力奖'
        // },
        {
          img: require('../../assets/img/about/honor/2017aaa.png'),
          name: '2017-2018合同信用等级AAA'
        },
        {
          img: require('../../assets/img/about/honor/2017zyqyj.png'),
          name: '2017年卓越企业奖'
        },
        {
          img: require('../../assets/img/about/honor/2018byl.png'),
          name: '2018白玉兰杯上海设计创新产品大赛“最佳技术创新奖”'
        },
        {
          img: require('../../assets/img/about/honor/2018shzdcp.png'),
          name: '2018上海市重点产品质量振兴攻关成果奖'
        },
        {
          img: require('../../assets/img/about/honor/2019dyjsh.png'),
          name: '2019第一届上海市知识产权创新奖'
        },
        {
          img: require('../../assets/img/about/honor/2020vr50.png'),
          name: '2020年VR50强企业'
        },
        {
          img: require('../../assets/img/about/honor/shsjkj.png'),
          name: '上海市松江区科技进步一等奖'
        },
        {
          img: require('../../assets/img/about/honor/sjgyhb.png'),
          name: '十佳公益伙伴企业'
        },
        {
          img: require('../../assets/img/about/honor/sjqyjszx.png'),
          name: '松江企业技术中心'
        },
        {
          img: require('../../assets/img/about/honor/zjcxlfw.png'),
          name: '最具创新力服务机构'
        },
        {
          img: require('../../assets/img/about/honor/zgsw.png'),
          name: '职工书屋'
        },
        // {
        //   img: require('../../assets/img/about/honor/2016年度新三板最具创新能力百强奖.png'),
        //   name: '2016年度新三板最具创新能力百强奖'
        // },
        {
          img: require('../../assets/img/about/honor/2019jhj.png'),
          name: '2019金号角奖'
        },
        {
          img: require('../../assets/img/about/honor/sjmxcpj.png'),
          name: '十佳明星产品奖'
        },
      ],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 7,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet(index, className) {
            return `<span class="${className} swiper-pagination-bullet-custom"></span>`
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.honor {
  overflow: hidden;
  background-color: #121212;
  padding: 0 16px;

  .title {
    margin-top: 80px;
    font-family: arial !important;;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #ffffff;
  }

  .mySwiper {
    height: 100%;
    margin-top: 30px;

    .mySlide {
      margin-bottom: 30px;

      .img {
        height: 100px;
      }

      .name {
        font-family: arial !important;;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
        /* or 19px */
        text-align: center;
        color: #ffffff;
        margin-top: 10px;
      }
    }

    .swiper-pagination-bullet-custom {
      width: 12px;
      height: 2px;
      //background: #414141;

      &.swiper-pagination-bullet-active {
        background-color: #F89227;
      }
    }
  }
}
</style>
