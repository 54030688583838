<template>
  <div class="partner">
    <h2 class="title">合作伙伴400+</h2>
    <div class="container">
      <div class="item" v-for="(item,index) in partners" :key="index">
        <div class="imgWrap">
          <img :src="item.logo" alt="">
        </div>
        <p class="name">{{item.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Partner",
  data() {
    return {
      partners: [
        {
          logo: require('../../assets/img/about/partners/hrzq.svg'),
          name: '华融证券'
        },
        {
          logo: require('../../assets/img/about/partners/htzq.svg'),
          name: '海通证券'
        },
        {
          logo: require('../../assets/img/about/partners/tfzq.svg'),
          name: '天风证券'
        },
        {
          logo: require('../../assets/img/about/partners/zjgs.svg'),
          name: '中金公司'
        },
        {
          logo: require('../../assets/img/about/partners/xyzq.svg'),
          name: '兴业证券'
        },
        // {
        //   logo: require('../../assets/img/about/partners/刚泰控股.svg'),
        //   name: '刚泰控股'
        // },
        {
          logo: require('../../assets/img/about/partners/xhhy.svg'),
          name: '星辉互娱'
        },
        {
          logo: require('../../assets/img/about/partners/shhd.svg'),
          name: '上海恒大'
        },
        {
          logo: require('../../assets/img/about/partners/huawei.svg'),
          name: '华为'
        },
        {
          logo: require('../../assets/img/about/partners/hp.svg'),
          name: '惠普'
        },
        {
          logo: require('../../assets/img/about/partners/lx.svg'),
          name: '联想'
        },
        {
          logo: require('../../assets/img/about/partners/mrgd.svg'),
          name: '迈锐光电'
        },
        {
          logo: require('../../assets/img/about/partners/envisionTEC.svg'),
          name: 'envisionTEC'
        },
        {
          logo: require('../../assets/img/about/partners/DM.svg'),
          name: 'DM'
        },
        {
          logo: require('../../assets/img/about/partners/ROKIT.svg'),
          name: 'ROKIT'
        },
        {
          logo: require('../../assets/img/about/partners/pls.svg'),
          name: '普利生'
        },
        // {
        //   logo: require('../../assets/img/about/partners/赛富亚洲.svg'),
        //   name: '赛富亚洲'
        // },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.partner {
  background-color: #121212;
  overflow: hidden;

  .title {
    margin-top: 80px;
    font-family: arial !important;;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
  }

  padding-bottom: 30px;

  .container {
    width: auto;
    margin: 0 auto;
    margin-top: 26px;
    padding: 0 10px;
    text-align: left;

    .item {
      width: 71px;
      margin-bottom: 33px;
      display: inline-block;
      text-align: center;

      .imgWrap {
        //width: 30px;
        height: 65px;
        overflow: hidden;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 66%;
        }
      }

      .name {
        font-family: arial !important;;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #ffffff;
        margin-top: 4px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100px;
        text-align: center;
        margin-left: -15px;
      }
    }
  }
}
</style>
