<template>
  <div class="about">
    <div class="introductionWrap">
      <Introduction></Introduction>
    </div>
    <div class="eventsWrap">
      <Events></Events>
    </div>
    <div class="technologyWrap">
      <Technology></Technology>
    </div>
    <div class="cultureWrap">
      <Culture></Culture>
    </div>
    <div class="honorWrap">
      <Honor></Honor>
    </div>
    <div class="partnerWrap">
      <Partner></Partner>
    </div>
  </div>
</template>

<script>
import Introduction from "@/components/about/Introduction";
import Events from "@/components/about/Events";
import Technology from "@/components/about/Technology";
import Culture from "@/components/about/Culture";
import Honor from "@/components/about/Honor";
import Partner from "@/components/about/Partner";

export default {
  name: 'About',
  components: {
    Introduction,
    Events,
    Technology,
    Culture,
    Honor,
    Partner
  }
}
</script>
