<template>
    <div class="technology">
        <h1 class="title">全产业链自主核心技术</h1>
        <p class="des">曼恒坚持自主创新，建立起内容、软件和硬件的统一标准，拥有软件引擎、内容平台、交互追踪和显示硬件等关键技术的自主知识产权</p>
        <div class="container">
            <div class="item" v-for="(item, index) in items" :key="index">
                <span class="num">{{ item.num }}+</span>
                <span class="line"></span>
                <span class="name">{{ item.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Technology',
        data() {
            return {
                items: [
                    {
                        num: 80,
                        name: '专利',
                    },
                    {
                        num: 3800,
                        name: '内容产品',
                    },
                    {
                        num: 2000,
                        name: '服务客户',
                    },
                    {
                        num: 100,
                        name: '渠道伙伴',
                    },
                    {
                        num: 210,
                        name: '软件著作权',
                    },
                ],
            }
        },
    }
</script>

<style scoped lang="scss">
    .technology {
        overflow: hidden;
        background-color: #121212;

        .title {
            font-family: arial !important;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            color: #ffffff;
            margin-top: 80px;
        }

        .des {
            font-family: arial !important;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 180%;
            text-align: justify;
            letter-spacing: 0.08em;
            color: #ffffff;
            margin-top: 30px;
            padding: 0 16px;
        }

        .container {
            padding: 0 16px;
            margin-top: 30px;
            margin-bottom: 50px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;

            .item {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                border: 1px solid #ffffff;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin: 0 10px 30px 10px;

                .num {
                    font-family: Arial;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    text-align: center;
                    letter-spacing: 0.005em;
                    color: #ffffff;
                }

                .line {
                    width: 60px;
                    border-bottom: 1px solid #ffffff;
                    margin: 8px 0;
                }

                .name {
                    font-family: arial !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    /* identical to box height */
                    text-align: center;
                    letter-spacing: 0.005em;
                    color: #ffffff;
                }
            }
        }
    }
</style>
