<template>
  <div class="culture">
    <h1 class="title">向上 守正</h1>
    <h2 class="subtitle">我们的企业文化</h2>
    <div class="container">
      <div class="cultureItem" v-for="(item,index) in cultures" :key="index"
           :style="{backgroundImage:`url(${item.bg})`}">
<!--        <div class="mask"></div>-->
        <div class="content">
          <img class="icon" :src="item.icon" alt="">
          <h2 class="name">{{item.title}}</h2>
          <p class="detail">{{item.detail}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Culture",
  data() {
    return {
      cultures: [
        {
          icon: require('../../assets/img/about/zqcj.svg'),
          bg: require('../../assets/img/about/zqcjbj.jpg'),
          title: '追求成就',
          detail: '富有理想和抱负，保持好奇心，不断学习拥抱变革，直面挑战，保持激情面对内外部的压力和挑战都要做到极致'
        },
        {
          icon: require('../../assets/img/about/jyfx.svg'),
          bg: require('../../assets/img/about/jyfxbj.jpg'),
          title: '敬业奉献',
          detail: '团队之间富有凝聚力，能够与其他团队相互配合，共同达成目标勇于承担责任，主动迎接新的任务和挑战，乐于分享专业知识与工作经验，与同事、公司共同成长'
        },
        {
          icon: require('../../assets/img/about/smbd.svg'),
          bg: require('../../assets/img/about/smbdbj.jpg'),
          title: '使命必达',
          detail: '脚踏实地，勤勤恳恳，兢兢业业克服障碍和困难，坚持到底，敢于突破，勇于尝试，不惧失败'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.culture {
  overflow: hidden;
  background-color: #1f1f1f;

  .title {
    font-family: arial !important;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #ffffff;
    margin-top: 80px;
  }

  .subtitle {
    font-family: arial !important;;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    margin-top: 20px;
  }

  .container {
    margin-top: 30px;
    text-align: center;
    margin-bottom: 50px;

    .cultureItem {
      display: inline-block;
      width: 343px;
      height: 300px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 30px;
      position: relative;

      .mask {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
      }

      .content {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .icon {
          margin-top: 30px;
        }

        .name {
          font-family: arial !important;;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          color: #FFFFFF;
          margin-top: 15px;
        }

        .detail {
          font-family: arial !important;;
          font-style: normal;
          //font-weight: 700;
          font-size: 14px;
          line-height: 26px;
          text-align: justify;
          letter-spacing: 0.08em;
          color: #FFFFFF;
          margin-top: 20px;
          padding: 0 30px;
        }
      }

    }
  }
}
</style>
