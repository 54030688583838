<template>
    <div class="events">
        <h2 class="title">曼恒大事记</h2>
        <div class="container">
            <div class="timeLineWrap" ref="scroll">
                <div class="scrollContent">
                    <div
                        class="scrollItem"
                        v-for="(item, index) in events"
                        :key="index"
                        :class="[{ active: activeIndex === index }, `scrollItem${index}`]"
                        @click="timeClick(index)"
                    >
                        <div class="time">{{ item.time }}</div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
            <div class="swiperWrap">
                <swiper class="eventSwiper" ref="mySwiper" :options="swiperOption">
                    <swiper-slide v-for="(slide, index) in events" :key="index">
                        <img class="img" :src="slide.img" alt="" />
                        <div class="eventsItems" v-for="(item, ins) in slide.des" :key="ins">
                            <!--              <p class="month">{{item.month}}</p>-->
                            <!--              <p class="detail" v-html="item.detail"></p>-->
                            <p class="detail"><span class="dot"></span>{{ item.detail }}</p>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
    import BScroll from 'better-scroll'

    export default {
        name: 'Events',
        data() {
            return {
                activeIndex: 0,
                swiperOption: {
                    autoHeight: true,
                    spaceBetween: 20,
                    on: {
                        slideChangeTransitionEnd: () => {
                            if (this.mySwiper) {
                                this.activeIndex = this.mySwiper.activeIndex
                                const scrollIndex = this.activeIndex - 1 < 0 ? 0 : this.activeIndex - 1
                                this.bs.scrollToElement(`.scrollItem${scrollIndex}`)
                            }
                        },
                    },
                },
                events: [
                    {
                        time: 2007,
                        img: require('../../assets/img/about/events/2007.jpg'),
                        des: [
                            {
                                detail: '3月6日上海曼恒数字技术有限公司成立于虹桥路333号交大慧谷',
                            },
                            {
                                detail: '决定投身于虚拟现实事业，成为中国较早的虚拟现实领域创业者之一',
                            },
                        ],
                    },
                    {
                        time: 2008,
                        img: require('../../assets/img/about/events/2008.jpg'),
                        des: [
                            {
                                detail: '推出多通道沉浸式虚拟现实硬件系统，并采用Quest3D工具开发VR内容',
                            },
                            {
                                detail: '签约第一个虚拟现实系统客户（湖南城市学院）',
                            },
                            {
                                detail: '参加首届SIGGRAPH ASIA展会，广泛学习全球VR技术',
                            },
                            {
                                detail: '8月28日全资子公司北京曼恒数字技术有限公司成立',
                            },
                        ],
                    },
                    {
                        time: 2009,
                        img: require('../../assets/img/about/events/2009.jpg'),
                        des: [
                            {
                                detail: '正式推出国内自研CAVE洞穴沉浸式虚拟现实显示系统',
                            },
                            {
                                detail: '签约首个五面CAVE洞穴式沉浸式系统客户（北京建筑工程学院）',
                            },
                            {
                                detail: '公司乔迁至上海市钦州路100号科创中心，成为市科委孵化企业',
                            },
                            {
                                detail: '荣获上海市科学技术学会“最具活力科技企业”称号',
                            },
                        ],
                    },
                    {
                        time: 2010,
                        img: require('../../assets/img/about/events/2010.jpg'),
                        des: [
                            {
                                detail: '公司组建VR引擎研发团队，大力投入虚拟现实核心软件技术与产品的开发',
                            },
                            {
                                detail: '启动自主知识产权的VR工具引擎DVS3D的研发',
                            },
                            {
                                detail: '行业内正式推出虚拟现实标准化沉浸式显示硬件IM系列产品',
                            },
                            {
                                detail: '签约世界500强企业（美国迪斯尼），公司产品及技术得到国际大公司的认可',
                            },
                        ],
                    },
                    {
                        time: 2011,
                        img: require('../../assets/img/about/events/2011.jpg'),
                        des: [
                            {
                                detail: '中华人民共和国副主席，时任上海市长韩正莅临曼恒',
                            },
                            {
                                detail: '荣获上海“高新技术企业”称号',
                            },
                            {
                                detail: '创始人周清会荣获第十届“上海IT青年十大新锐”称号',
                            },
                            {
                                detail: '签约首个能源行业客户（中广核集团），为其提供虚拟现实硬件与内容开发服务',
                            },
                        ],
                    },
                    {
                        time: 2012,
                        img: require('../../assets/img/about/events/2012.jpg'),
                        des: [
                            {
                                detail: '启动自主知识产权的红外光学追踪系统G-Motion V1.0的研发',
                            },
                            {
                                detail: '推出了G-MAGIC V1.0变形沉浸式虚拟现实硬件产品',
                            },
                            {
                                detail: '签约首个军工领域的虚拟现实客户',
                            },
                            {
                                detail: '荣获“上海市设计创新示范企业”称号',
                            },
                        ],
                    },
                    {
                        time: 2013,
                        img: require('../../assets/img/about/events/2013.jpg'),
                        des: [
                            {
                                detail: '获得首份超过千万的合同-中国航发集团航空发动机虚拟设计系统',
                            },
                            {
                                detail: '启用上海合川路2679号办公室，公司进入规模化的发展阶段',
                            },
                            {
                                detail: '公司获得相卫集团3500万的融资',
                            },
                        ],
                    },
                    {
                        time: 2014,
                        img: require('../../assets/img/about/events/2014.jpg'),
                        des: [
                            {
                                detail: '行业内正式推出3D-LED沉浸式虚拟现实显示交互产品',
                            },
                            {
                                detail: '公司启动3D打印业务战略，并投资入股3D打印公司（上海普利生）',
                            },
                            {
                                detail: '入选智联招聘“2014中国年度最佳雇主30强”榜单',
                            },
                        ],
                    },
                    {
                        time: 2015,
                        img: require('../../assets/img/about/events/2015.jpg'),
                        des: [
                            {
                                detail: '联合普利生推出锐打RP400面阵曝光技术3D打印机',
                            },
                            {
                                detail: '完成广州、成都、武汉、沈阳、西安、济南等分支机构的区域布局',
                            },
                            {
                                detail: '公司获得欧德、湖杉资本2404万融资',
                            },
                            {
                                detail:
                                    '公司完成股改，华融证券、海通证券、天风证券等成为首批做市商，融资4730万',
                            },
                        ],
                    },
                    {
                        time: 2016,
                        img: require('../../assets/img/about/events/2016.jpg'),
                        des: [
                            {
                                detail: 'DVS3D升级为IdeaVR，率先推出行业内异地多人协同VR工具引擎',
                            },
                            {
                                detail: '推出VRDO虚拟现实工业可视化工具引擎',
                            },
                            {
                                detail: '推出G-Motion全身动捕交互追踪产品',
                            },
                            {
                                detail:
                                    '公司获得赛富亚洲、中金公司和兴业证券等多家知名投资机构和星辉娱乐、爱仕达等上市公司2.05亿的融资',
                            },
                        ],
                    },
                    {
                        time: 2017,
                        img: require('../../assets/img/about/events/2017.jpg'),
                        des: [
                            {
                                detail: '明确VR内容平台战略，并推出VRBOX虚拟现实内容平台',
                            },
                            {
                                detail: '与德国EnvisionTEC合作，推出生物3D打印业务',
                            },
                            {
                                detail: '曼恒参与并保障的国产大飞机C919首飞试飞系统备受肯定，获得中国商飞的感谢信，周清会获得个人荣誉证书与勋章',
                            },
                            {
                                detail: '曼恒作为技术单位参与2017年度国家重点研发计划“公共安全风险防控与应急技术装备”专项',
                            },
                        ],
                    },
                    {
                        time: 2018,
                        img: require('../../assets/img/about/events/2018.jpg'),
                        des: [
                            {
                                detail: '中央政治局常委，国务院总理，时任上海市委书记李强莅临曼恒',
                            },
                            {
                                detail: '公司被认定为“上海虚拟仿真教学工程技术研究中心”',
                            },
                            {
                                detail: '国内率先推出VR内容适配软件LinkVR V1.0',
                            },
                            {
                                detail: '签约世界500强企业（美国艾默生电气公司），公司业务拓展到智能制造领域',
                            },
                            {
                                detail: '荣获2018年度公益之申“十佳公益伙伴企业”称号',
                            },
                        ],
                    },
                    {
                        time: 2019,
                        img: require('../../assets/img/about/events/2019.jpg'),
                        des: [
                            {
                                detail: '成为华为5G+VR的行业战略合作伙伴，并联合推出行业内领先的5G串流软件FlyVR(飞流)',
                            },
                            {
                                detail: '推出行业内较早VR智能中控系统（八爪鱼）',
                            },
                            {
                                detail: '作为中国慕课大会指定VR软件及内容支持企业，获得教育部感谢信',
                            },
                            {
                                detail: '入选2019年世界VR产业大会“中国VR50强企业”榜单',
                            },
                        ],
                    },
                    {
                        time: 2020,
                        img: require('../../assets/img/about/events/2020.jpg'),
                        des: [
                            {
                                detail: '发布新一代VR引擎IdeaVR 2021(阿凡达)',
                            },
                            {
                                detail: '推出新一代VR大空间解决方案（小蜜蜂）',
                            },
                            {
                                detail: '第二届全国电子信息行业新技术应用职业技能竞赛-“曼恒杯”VR技术应用大赛圆满收官',
                            },
                            {
                                detail: '参与“国家危险化学品应急救援实训演练（大庆）基地”建设'
                            },
                            {
                                detail: '公司搬迁至顺庆路500号河图公园，打造VR产业链生态的创新地',
                            },
                        ],
                    },
                    {
                        time: 2021,
                        img: require('../../assets/img/about/events/event_2021.jpg'),
                        des: [
                            {
                                detail: '2021年全国职业院校技能大赛虚拟现实（VR）制作与应用赛项（中职组）圆满举办',
                            },
                            {
                                detail: '打造新一代工程技术领域VR可视化工具VRDO2021(大黄蜂）',
                            },
                            {
                                detail: '发布首款VR一体机·7光',
                            },
                            {
                                detail: '成为第一届全国医学虚拟仿真实验创新大赛技术支持单位'
                            },
                        ],
                    },
                    {
                        time: 2022,
                        img: require('../../assets/img/about/events/event_2022.jpg'),
                        des: [
                            {
                                detail: '曼恒实现了北京、上海双总部布局',
                            },
                            {
                                detail: '成为全国新职业和数字技术技能大赛技术支持单位',
                            },
                            {
                                detail: '成为第46届世界技能大赛技术展项企业之一',
                            }
                        ],
                    },
                ],
            }
        },
        mounted() {
            this.initBs()
            this.$nextTick(() => {
                this.mySwiper = this.$refs.mySwiper.$swiper
            })
        },
        methods: {
            initBs() {
                this.bs = new BScroll(this.$refs.scroll, {
                    scrollX: true,
                    click: true,
                })
            },
            timeClick(index) {
                this.activeIndex = index
                this.mySwiper.slideTo(index)
            },
        },
    }
</script>

<style scoped lang="scss">
    .events {
        overflow: hidden;
        background-color: #1f1f1f;

        .title {
            font-family: arial !important;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            color: #ffffff;
            margin-top: 80px;
        }

        .container {
            margin-top: 30px;
            padding-bottom: 80px;

            .timeLineWrap {
                width: 100%;
                height: 44px;
                overflow: hidden;
                white-space: nowrap;
                text-align: left;

                .scrollContent {
                    display: inline-block;
                    height: 100%;

                    .scrollItem {
                        padding: 14px 17px;
                        display: inline-block;
                        font-family: arial;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 15px;
                        color: #ffffff;
                        position: relative;
                        text-align: center;

                        &.active {
                            .time {
                                color: #f89227;
                            }

                            .line {
                                width: calc(100% - 34px);
                                height: 2px;
                                background-color: #f89227;
                                position: absolute;
                                bottom: 0;
                            }
                        }
                    }
                }
            }

            .swiperWrap {
                border-top: 1px solid #333333;
                padding: 0 16px;

                .eventSwiper {
                    .eventsItems {
                        margin-top: 20px;

                        .month {
                            font-family: arial !important;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 14px;
                            text-align: justify;
                            color: #ffffff;
                        }

                        .detail {
                            font-family: arial !important;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 24px;
                            text-align: justify;
                            color: #ffffff;
                            margin-top: 10px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: baseline;

                            .dot {
                                flex: none;
                                width: 6px;
                                height: 6px;
                                background: #f89227;
                                //border-radius: 50%;
                                margin-right: 14px;
                                position: relative;
                                top: -2px;
                            }
                        }
                    }

                    .img {
                        width: 100%;
                        margin-top: 30px;
                    }
                }
            }
        }
    }
</style>
