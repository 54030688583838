<template>
    <div class="introduction">
        <div class="bg"></div>
        <div class="content">
            <h2 class="title">公司简介</h2>
            <p class="des">
                上海曼恒数字技术股份有限公司是一家专注VR、AR、MR技术十五年的高新技术企业，拥有一支稳定、优秀的研发团队，聚焦教育领域、应急安全、高端工业三大行业，
                在国内和国际的虚拟现实行业中均具有影响力。公司将以创新引领发展，以科技成就收获，成为XR行业的领军企业。
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Introduction',
    }
</script>

<style scoped lang="scss">
    .introduction {
        background-color: #121212;
        overflow: hidden;

        .bg {
            width: 100%;
            height: 224px;
            background-image: url('../../assets/img/about/banner.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .content {
            .title {
                font-family: arial !important;
                font-style: normal;
                font-weight: bold;
                font-size: 28px;
                color: #ffffff;
                margin-top: 80px;
            }

            .des {
                font-family: arial !important;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 180%;
                /* or 25px */
                text-align: justify;
                letter-spacing: 0.08em;
                color: #ffffff;
                margin-top: 30px;
                padding: 0 16px;
                margin-bottom: 80px;
            }
        }
    }
</style>
